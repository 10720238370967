"use client";

import { useContext } from "react";

import ButtonWithIcon from "@customer-ui/components/ButtonWithIcon";
import Card from "@customer-ui/components/Card";
import Image from "@customer-ui/components/Image";
import StoriesButtonText from "@microsite/components/organisms/StoriesButtonText";
import { ProductsContext } from "@microsite/contexts/products";
import usePrice from "@microsite/features/product/hooks/usePrice";
import scrollToProductSection from "@microsite/lib/navigation/scrollToProductSection";
import { LeadDiscount } from "@superfiliate/graphql-sdk/src/lib/__generated__";
import cn from "@utils/cn";
import currency from "@utils/formatters/currency";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";
import findMicrositeSection from "@utils/microsites/personalization/findMicrositeSection";
import {
  PersonalizationSectionStories,
  Sections,
} from "@utils/microsites/types/personalization";
import { OrderInterval } from "@utils/types";

const StoriesDesktopCallToAction: React.FC<{
  sectionData: PersonalizationSectionStories;
}> = ({ sectionData }) => {
  const { campaignSuperfiliate, personalization } = useContext(
    CampaignSuperfiliateMicrositeContext,
  );

  const leadDiscount =
    campaignSuperfiliate.campaign.campaignReward!.leadDiscount;
  const leadDiscountType =
    campaignSuperfiliate.campaign.campaignReward!.leadDiscountType;

  const { product, variant, sellingOption, sellingPlan } =
    useContext(ProductsContext);

  const productSection = findMicrositeSection(
    personalization,
    Sections.Product,
  );
  const showPrice = !productSection?.ctaPriceDisabled;

  const isProductCard = !!sectionData?.productCard;
  const isSubscription = sellingOption === OrderInterval.SUBSCRIPTION;

  const prices = usePrice({ variant, sellingPlan, discount: leadDiscount });

  const comparePrice = isSubscription
    ? prices[OrderInterval.SUBSCRIPTION].originalPrice
    : prices[OrderInterval.ONE_TIME].originalPrice;

  const actualPrice = isSubscription
    ? prices[OrderInterval.SUBSCRIPTION].actualPrice
    : prices[OrderInterval.ONE_TIME].actualPrice;

  const discountedPrice = isSubscription
    ? prices[OrderInterval.SUBSCRIPTION].discountedPrice
    : prices[OrderInterval.ONE_TIME].discountedPrice;

  if (!isProductCard || !variant || !prices) {
    return (
      <ButtonWithIcon
        className="w-fit select-none px-6"
        color="primary"
        onClick={scrollToProductSection}
      >
        <StoriesButtonText />
      </ButtonWithIcon>
    );
  }

  const mediaID = variant.media?.mediaId || product?.pickedMedias?.[0]?.mediaId;

  return (
    <Card
      className={"bg-primary flex items-center p-5 w-full !border-transparent"}
    >
      <Image
        alt="Product Image"
        className="rounded-[calc(0.6*var(--sf-custom-radius))] object-cover size-[114px]"
        height={114}
        src={mediaID || "#"}
        width={114}
      />

      <div className="flex flex-col w-36 ml-5 mr-3 text-left text-primary-content">
        <span className="font-body font-bold text-md">
          {product?.title || variant?.publicFields?.title || ""}
        </span>

        {showPrice && (
          <div className="flex flex-row gap-x-2">
            {comparePrice !== discountedPrice &&
              leadDiscountType == LeadDiscount.OrderDiscount && (
                <span
                  className={cn(
                    "line-through opacity-60 text-md font-normal font-sans",
                  )}
                >
                  {currency.shortFormat(comparePrice!)}
                </span>
              )}

            <span className={cn("text-md font-semibold font-sans")}>
              {currency.shortFormat(
                leadDiscountType == LeadDiscount.OrderDiscount
                  ? discountedPrice!
                  : actualPrice!,
              )}
            </span>
          </div>
        )}
      </div>

      <ButtonWithIcon
        className="w-auto select-none px-6 h-fit ml-auto"
        color="base"
        onClick={scrollToProductSection}
      >
        <StoriesButtonText />
      </ButtonWithIcon>
    </Card>
  );
};

export default StoriesDesktopCallToAction;
