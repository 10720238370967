export enum Services {
  Microsite = "microsites",
  Portal = "portal",
}

export enum MicrositeStatus {
  ACTIVE = "active",
  PENDING_ACTIVATION = "pending_activation",
}

export enum OrderInterval {
  SUBSCRIPTION = "subscription",
  ONE_TIME = "one-time",
}

enum EnumBuyModalities {
  AllPossible = "all_possible",
  OnlySubscription = "only_subscription",
  OnlyOneTime = "only_one_time",
}
export type BuyModalities = EnumBuyModalities | `${EnumBuyModalities}`;
export const BUYING_MODALITIES = {
  subscription: ["all_possible", "only_subscription"],
  one_time: ["all_possible", "only_one_time"],
} as Record<"subscription" | "one_time", BuyModalities[]>;

export enum ShareType {
  Copy,
  Native,
}

export type SocialItemTypes =
  | "sms"
  | "whatsapp"
  | "facebook"
  | "twitter"
  | "email";

export type CommonMediaProvider =
  | "mux"
  | "cloudflare"
  | "shopify"
  | "youtube"
  | "vimeo";

export interface CommonMedia {
  mediaId: string;
  mediaType: "video" | "image";
  mediaProvider: CommonMediaProvider;
}

export interface UGCMediaProps {
  media: CommonMedia | undefined | null;
  url: string;
  kind: "image" | "video";
  fit: "cover" | "contain";
  useOriginalMedia?: boolean;
  controls?: boolean;
  dataSaver?: boolean;
  videoRef?: React.RefObject<HTMLVideoElement | null>;
}

/**
 * Make Omit work with union types, which is not supported by default by Omit
 *
 * @note - Typescript contains an Exclude utility type that can be used to remove a type
 *         from a unions, but based on its internal implementation, it does not work as
 *         expected, given it uses `typeof` to infer the final keys, considering only keys
 *         that are present in all types of the union (intersection)
 *
 * @reference - https://www.reddit.com/r/typescript/comments/qvad8a/comment/hkyyp6b
 */
export type UnionOmit<T, K extends string | number | symbol> = T extends unknown
  ? Omit<T, K>
  : never;
